import React, { useState, useRef,useMemo  } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Button, Input, Space } from 'antd';
import BarSide2 from '../../BarSide2';
import { Container } from 'react-bootstrap';
import { CSVLink } from 'react-csv'; 
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, ToastContainer } from 'react-toastify'; // For toast notifications
import 'react-toastify/dist/ReactToastify.css';
import { RiOpenaiFill } from "react-icons/ri";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import axios from "../../../components/Axios"

const KeywordDetails = () => {
  const location = useLocation();
  const { keywordData } = location.state;

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  // const [filterType, setFilterType] = useState('include');

   
  const [filteredInfo, setFilteredInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // Track selected row keys
  const [selectedRows, setSelectedRows] = useState([]); // Track selected row objects
 


  if (!keywordData) {
    return <div>No data available</div>;
  }

  console.log(keywordData, "keywordData"); // Ensure keywordData is logged for debugging

  // Flatten the data structure
  // const flattenedData = keywordData.entities.flatMap((entity) =>
  //   entity.topics.flatMap((topic) =>
  //     topic.subtopics.flatMap((subtopic) =>
  //       subtopic.keywords.map((keyword) => ({
  //         _id: `${subtopic._id}-${keyword}`,
  //         keyword: keyword,
  //         subtopic: subtopic.name,
  //         topic: topic.name,
  //         entity: entity.name,
  //       }))
  //     )
  //   )
  // );

  // const flattenedData = keywordData.entities.flatMap((entity) =>
  //   entity.topics.flatMap((topic) =>
  //     topic.subtopics.flatMap((subtopic) =>
  //       subtopic.keywords.map((keyword, index) => ({
  //         _id: `${subtopic._id}-${index}-${keyword}`,
  //         keyword: keyword,
  //         subtopic: subtopic.name,
  //         topic: topic.name,
  //         entity: entity.name,
  //       }))
  //     )
  //   )
  // );

  const flattenedData = keywordData.entities.flatMap((entity) =>
    entity.topics.flatMap((topic) =>
      topic.subtopics.flatMap((subtopic) =>
        subtopic.keywords.map((keyword, index) => ({
          _id: `${subtopic._id}-${index}-${keyword}`,
          keyword: keyword,
          searchVolume: subtopic.keywordMetrics?.[keyword] || '', // Add search volume from keywordMetrics
          subtopic: subtopic.name,
          topic: topic.name,
          entity: entity.name,
        }))
      )
    )
  );
  

  // Extract unique values for filtering
  const uniqueTopics = [...new Set(flattenedData.map((item) => item.topic))];
  const uniqueSubtopics = [...new Set(flattenedData.map((item) => item.subtopic))];

  // Extract unique entities for filtering
const uniqueEntities = [...new Set(flattenedData.map((item) => item.entity))];

// Create filter objects for each unique entity
const entityFilters = uniqueEntities.map((entity) => ({ text: entity, value: entity }));


  // Create filter objects for each unique value
  const topicFilters = uniqueTopics.map((topic) => ({ text: topic, value: topic }));
  const subtopicFilters = uniqueSubtopics.map((subtopic) => ({ text: subtopic, value: subtopic }));

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };

  // const handleChatGptClick = (record) => {
  //   // Prepare data
  //   const data = {
  //     keyword: record.keyword,
  //     subtopic: record.subtopic,
  //     topic: record.topic,
  //   };
  
  //   // Send message to content script
  //   window.postMessage(
  //     {
  //       source: 'react-app', // Unique identifier
  //       type: 'OPEN_CHATGPT',
  //       payload: data,
  //     },
  //     '*'
  //   );
  // };

  // Send selected rows as an array to the content script
   
    const handleChatGptClick = () => {
      toast.info('If ChatGPT page is not opening, please refresh the page and try again.', {
        autoClose: 2000
      });
      const data = {
        cluster: selectedRows.map(({ _id, ...rest }) => rest), // Exclude _id from each row
      };
  console.log(data, "data of cluster");
      // Send message to content script
      window.postMessage(
        {
          source: 'react-app', // Unique identifier
          type: 'OPEN_CHATGPT',
          payload: data,
        },
        '*'
      );
    };

    const handleClaudeClick = () => {
      // const data = { cluster: selectedRows };
      toast.info('If Claude page is not opening, please refresh the page and try again.', {
        autoClose: 2000
      });
      const data = {
        cluster: selectedRows.map(({ _id, ...rest }) => rest), // Exclude _id from each row
      };
      console.log(data, "data of cluster for Claude");
      window.postMessage(
        {
          source: 'react-app',
          type: 'OPEN_CLAUDE',
          payload: data,
        },
        '*'
      );
    };
    
    const handleGeminiClick = () => {
      // const data = { cluster: selectedRows };
      toast.info('If Gemini page is not opening, please refresh the page and try again.', {
        autoClose: 2000
      });
      const data = {
        cluster: selectedRows.map(({ _id, ...rest }) => rest), // Exclude _id from each row
      };
      console.log(data, "data of cluster for Gemini");
      window.postMessage(
        {
          source: 'react-app',
          type: 'OPEN_GEMINI',
          payload: data,
        },
        '*'
      );
    };  

  // Function to handle search functionality
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm(); 
    setSearchText(selectedKeys[0]); 
    setSearchedColumn(dataIndex); 
    setFilteredInfo((prevFilters) => ({
      ...prevFilters,
      [dataIndex]: selectedKeys[0], // Set the search filter in the filteredInfo
    }));
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setFilteredInfo((prevFilters) => ({
      ...prevFilters,
      keyword: null,
    }));
  };
  

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            // type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Include
          </Button>
          <Button
          onClick={() => handleExclude(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Exclude
        </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          <Button type="link" size="small" onClick={() => close()}>close</Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
  });

// Exclude handler function
const handleExclude = (selectedKeys, confirm, dataIndex) => {
  confirm();
  setSearchText(selectedKeys[0]); // Store the exclude text
  setSearchedColumn(dataIndex);
  setFilteredInfo((prevFilters) => ({
    ...prevFilters,
    [dataIndex]: `!${selectedKeys[0]}`, // Prefix with `!` to indicate exclusion
  }));
};

  const filteredData = flattenedData.filter((item) => {
    let isMatch = true;
    
    // Apply Topic filter
    if (filteredInfo.topic && filteredInfo.topic.length > 0) {
      isMatch = isMatch && filteredInfo.topic.includes(item.topic);
    }
    
    // Apply Subtopic filter
    if (filteredInfo.subtopic && filteredInfo.subtopic.length > 0) {
      isMatch = isMatch && filteredInfo.subtopic.includes(item.subtopic);
    }

    // Apply Entity filter
  if (filteredInfo.entity && filteredInfo.entity.length > 0) {
    isMatch = isMatch && filteredInfo.entity.includes(item.entity);
  }

    // Apply Search filter on 'text' (Keyword column)
  // if (filteredInfo.keyword && filteredInfo.keyword.length > 0) {
  //   isMatch = isMatch && item.keyword.toLowerCase().includes(filteredInfo.keyword.toLowerCase());
  // }

   // Apply Search filter on 'keyword' (Keyword column)
   if (filteredInfo.keyword) {
    const excludeMode = filteredInfo.keyword.startsWith('!');
    const filterText = excludeMode ? filteredInfo.keyword.slice(1) : filteredInfo.keyword;
    isMatch = isMatch && (
      excludeMode 
        ? !item.keyword.toLowerCase().includes(filterText.toLowerCase())
        : item.keyword.toLowerCase().includes(filterText.toLowerCase())
    );
  }

   // Apply Exclude filter (opposite of search)
   
    
    return isMatch;
  });
  

 // Define columns for the main table
 const columns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    width: 35,
    render: (text, record, index) => index + 1, // Display row number starting from 1
  },
  {
    title: 'Keyword',
    dataIndex: 'keyword',
    key: 'keyword',
    width: 200,
    ...getColumnSearchProps('keyword'),
    onCell: () => {
      return {
        style: {
          paddingLeft: '30px',
        },
      };
    },
    onHeaderCell: () => {
      return {
        style: {
          paddingLeft: '30px',
        },
      };
    },
  },
  {
    title: 'Search Volume',
    dataIndex: 'searchVolume',
    key: 'searchVolume',
    render: (volume) => volume.toLocaleString(), // Format number with commas
    sorter: (a, b) => a.searchVolume - b.searchVolume, // Enable sorting by search volume
  },
  {
    title: 'Subtopic',
    dataIndex: 'subtopic',
    key: 'subtopic',
    width: 200,
    filters: subtopicFilters,
    filteredValue: filteredInfo.subtopic || null,
    onFilter: (value, record) => record.subtopic.includes(value), 
  },
  {
    title: 'Topic',
    dataIndex: 'topic',
    key: 'topic',
    width: 200,
    filters: topicFilters,
    filteredValue: filteredInfo.topic || null,
    onFilter: (value, record) => record.topic.includes(value),
  },{
    title: 'Entity',
    dataIndex: 'entity',
    key: 'entity',
    width: 200,
    filters: entityFilters,  // Add entity filters
    filteredValue: filteredInfo.entity || null,  // Track filtered value for entity
    onFilter: (value, record) => record.entity.includes(value),  // Filtering logic
  }
  // {
  //   title: '', // ChatGPT Icon Column
  //   key: 'chatgpt',
  //   width: 50,
  //   render: (text, record) => (
  //     <Button
  //       type="link"
  //       icon={<RiOpenaiFill size={24} color="black" />}
  //       onClick={() => handleChatGptClick(record)}
  //       className="chatgpt-button"
  //     />
  //   ),
  // }
];

  const rowSelection = {
    selectedRowKeys, // Controlled selected row keys
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRowKeys(selectedKeys);
      setSelectedRows(selectedRows);
      console.log(`selectedRowKeys: ${selectedKeys}`, 'selectedRows: ', selectedRows);
    },
  };
  
  const headers = [
    { label: 'Keyword', key: 'keyword' },
    { label: 'Subtopic', key: 'subtopic' },
    { label: 'Topic', key: 'topic' },
    { label: 'Entity', key: 'entity' },
  ];

const keywordsToCopy = selectedRows.map(row => row.keyword).join('\n'); // Use '\n' for new lines or ', ' for commas





  return (
    <>
     <ToastContainer 
        position="top-right" 
        autoClose={5000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover 
      />
<main className="d-flex flex-nowrap">
  <BarSide2 />
  <Container>
    <div className="d-flex flex-column flex-grow-1 p-4">
      <div style={{ overflowY: "auto", height: "calc(100vh - 24px)" }}>
        <div className="container mt-3">
          <div className="row">
            <div className="col-md-12">
              <div className="ra-home border border-gray-300 bg-white rounded-lg">
                <div className="d-flex justify-content-between">
               <h3>{keywordData.keyword}</h3> 
               <div style={{ marginBottom: '6px' }}>
               <Button
  type="primary"
  style={{ backgroundColor: "#1677ff", marginRight: '8px' }}
  onClick={handleChatGptClick}
>
  Use ChatGPT
</Button> <Button
  type="primary"
  style={{ backgroundColor: "#1677ff", marginRight: '8px' }}
  onClick={handleClaudeClick}
>
  Use Claude
</Button>


<Button
  type="primary"
  style={{ backgroundColor: "#1677ff", marginRight: '8px' }}
  onClick={handleGeminiClick}
>
  Use Gemini
</Button>
               


<CopyToClipboard 
  text={keywordsToCopy}
  onCopy={() => {
    if (keywordsToCopy.length > 0) {
      toast.success('Keywords copied to clipboard!');
    } else {
      toast.info('No keywords selected to copy.');
    }
  }}
>
  <Button type="primary" style={{ backgroundColor: "#1677ff", marginRight: '8px'}}>
    Copy To Clipboard
  </Button>
</CopyToClipboard>

  <CSVLink data={filteredData} headers={headers} filename="keywords.csv"  style={{ textDecoration: 'none' }}>
    <Button type="primary" style={{ backgroundColor: "#1677ff"}}>Export Data</Button>
  </CSVLink>
</div>
                </div> {/* Close justify-content-between div */}
              </div> {/* Close ra-home div */}
            </div> {/* Close col-md-12 div */}
          </div> {/* Close row div */}





    <div className='row'>
    <div className="container mt-3">
          <div className="row">
            <div className="col-md-12">
            <div className="ra-home border border-gray-300 bg-white rounded-lg">
            <div className="d-flex justify-content-between">
            <Table
                          dataSource={filteredData}
                          columns={columns}
                          rowKey="_id" // Ensure 'key' is unique; adjust if necessary
                          rowSelection={rowSelection} // Add rowSelection
                          // pagination={false}
                          pagination={{ pageSize: 500 }}
                          scroll={{ x: 'max-content' }}
                          onChange={handleChange}
                          style={{ width: '100%' }}
                        />

              </div></div></div></div></div>
    </div>


        </div> {/* Close container div */}
        
      </div> {/* Close auto overflow div */}

    {/*  */} 
   

    </div> {/* Close flex-column div */}
  </Container> {/* Close Container */}
</main> {/* Close main */}
    </>
  );
};

export default KeywordDetails;
