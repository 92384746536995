import React, {useState, useEffect} from 'react'
import BarSide2 from "../../BarSide2";
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col, Typography, Table, Select, Input , Button, Modal } from 'antd';
import { BsCaretRight } from 'react-icons/bs';
import { Container } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import axios from "../../../components/Axios";
import { Link } from 'react-router-dom';


const {Option} = Select;

function ClusterTool() {

      const [keyword, setKeyword] = useState('');
      const [niche, setNiche] = useState('');
       const [loading, setLoading] = useState(false);


    
      const handleKeywordChange = (e) => setKeyword(e.target.value);
      const handleNicheChange = (e) => setNiche(e.target.value);

        // New states for modal and keywords
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customKeywords, setCustomKeywords] = useState('');
     
    
    const userDataString = localStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;
    const [responseData, setResponseData] = useState([]); // State to hold response data

    const showModal = () => setIsModalVisible(true);
    const handleModalCancel = () => {
      setIsModalVisible(false);
      setCustomKeywords(''); // Clear textarea when closing modal
    };
  
    const handleModalOk = () => {
      setIsModalVisible(false);
    };
  


const handleSubmit = async () => {

  const keywordsArray = customKeywords
      .split('\n')
      .map(keyword => keyword.trim())
      .filter(keyword => keyword.length > 0).slice(0, 1000);

    // Validation checks
    if (!keyword || !niche ) {
      toast.error('Please fill in all required fields.');
      return;
    }

    console.log(keywordsArray, "keywordsArray")

    const requestData = {
      keyword,
      niche,
      
      openaiapiKey: userData?.openaiApi,
      usersOwnKeywords: keywordsArray,
      userId: userData?._id,

    };
console.log(requestData, "requestData")
    setLoading(true);
    try {
      const response = await axios.post('/cluster/create-cluster', requestData);
      toast.success('Request successful!');
      console.log(response.data);
      setResponseData(response.data);
    } catch (error) {
      toast.error(`Error: ${error.response ? error.response.data.error : error.message}`);
    } finally {
      setLoading(false);
    }
  };
    

const [loadingDeleteRecord, setloadingDeleteRecord] = useState(false)

  const handleDeleteMergeResponse = async (record) => {
    setloadingDeleteRecord(true);
    try {
      toast.info("Deleting...");
  
      // Send the delete request using the record's _id
      const response = await axios.post('/cluster/remove-merged-response', {
        userId: userData._id, // Replace with the actual user ID
        mergedResponseId: record._id, // _id from the record being deleted
      });
  
      if (response.status === 200) {
        toast.success('Keyword deleted successfully!');
        setResponseData(response.data.data);
        // Optionally refresh the table data or remove the deleted row from the state
      }
    } catch (error) {
      toast.error('Error deleting MergedResponse. Please try again.');
    } finally {
      setloadingDeleteRecord(false);
    }
  };
  


  const columns = [
    {
      title: 'Keyword',
      dataIndex: 'keyword',
      key: 'keyword',
      render: (text, record) => <Link to={`/keyword/${record._id}`} state={{ keywordData: record }}>{text}</Link>,
    },
    {
      title: 'Niche',
      dataIndex: 'niche',
      key: 'niche',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: '', // Column for the delete icon
      key: 'delete',
      render: (text, record) => (
        <Button
          type="link"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              onClick={() => handleDeleteMergeResponse(record)} // Pass the entire record to delete
            >
              <path
                d="m376-300 104-104 104 104 56-56-104-104 104-104-56-56-104 104-104-104-56 56 104 104-104 104 56 56Zm-96 180q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520Zm-400 0v520-520Z"
                fill="red"
              />
            </svg>
          }
        />
      ),
    },
    // Add more columns as needed
  ];



  useEffect(() => {
    const fetchData = async () => {
      const timestamp = new Date().getTime();
      
      // Display the loading toast and get its ID
      const toastId = toast.loading('Loading keywords...');
      
      try {
        const response = await axios.get(`/cluster/userdocs/${userData._id}?timestamp=${timestamp}`);
        setResponseData(response.data);
        console.log(response.data);
        
        // Update the toast to success
        toast.update(toastId, {
          render: 'Keywords loaded successfully!',
          type: 'success',
          isLoading: false,
          autoClose: 3000, // Auto close after 3 seconds
        });
      } catch (error) {
        console.error('Error fetching user documents:', error);
        
        // Update the toast to error
        toast.update(toastId, {
          render: 'Failed to fetch user documents.',
          type: 'error',
          isLoading: false,
          autoClose: 5000, // Auto close after 5 seconds
        });
      }
    };

    fetchData();
  }, []);




  return (
    <>
    <ToastContainer
      position="top-right"
      autoClose={10000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
    <main className="d-flex flex-nowrap">
      <BarSide2 />
      <Container>
        <div className="d-flex flex-column flex-grow-1 p-4">
          <div style={{ overflowY: "auto", height: "calc(100vh - 24px)" }}>
            <div className="container mt-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="ra-home border border-gray-300 bg-white rounded-lg">
                    <div className="d-flex justify-content-between">
                      <h4>Topical Mapping Tool</h4>
                      <Row align="middle" className="tutDiv">
                        <Col>
                          <BsCaretRight style={{ verticalAlign: 'middle' }} className="tutColor" />
                        </Col>
                        <Col>
                          <Typography.Text className="tutColor tutText">
                            <a className='colorNamea' href="https://www.youtube.com/watch?v=h_kMhbWHNyc" target="_blank" rel="noopener noreferrer">
                              VIDEO TUTORIAL
                            </a>
                          </Typography.Text>
                        </Col>
                      </Row>
                    </div>
                    <div className="keyword-discovery-container">
                      <Typography.Paragraph className="keyword-description">
                        A tool that simplifies the process of creating topical maps for SEO by automating the research and organization of related topics. It identifies core topics, clusters them into relevant subtopics, and provides a structured roadmap to improve content planning and search engine visibility. This tool is designed to save time and ensure a comprehensive approach to content development.
                      </Typography.Paragraph>
                    </div>
                    <div style={{ marginTop: '14px' }}>
                        {/* Inputs */}
                      <Row gutter={8}>
                      <Col span={6}>
        <Input
          placeholder="Enter keyword"
          value={keyword}
          onChange={handleKeywordChange}
        />
      </Col>
      <Col span={6}>
        <Input
          placeholder="Enter niche"
          value={niche}
          onChange={handleNicheChange}
        />
      </Col>

      <Col span={6}>
                            <Button 
                              onClick={showModal}
                              style={{ marginBottom: '16px', width: '100%' }}
                            >
                              Add Your Own Keywords (Optional)
                            </Button>
                          </Col>
   
      <Col span={6}>
      <Button key="submit" className='' style={{width: '100%'}} loading={loading} onClick={handleSubmit}>
            Submit
          </Button>,
      </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              {/* Table */}
              <div className="row">
                <div className="col-md-12">
                  <div className='mt-5'>
                  <Table dataSource={responseData} columns={columns} pagination={{ pageSize: 50 }} rowKey="_id" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </main>

    <Modal
        title="Add Your Own Keywords"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={800}
        okButtonProps={{ type: "default" }}
      >
        <div style={{ marginBottom: 16 }}>
          <Typography.Text type="secondary">
            Add one keyword per line (maximum 1000 keywords)
          </Typography.Text>
        </div>
        <Input.TextArea
          value={customKeywords}
          onChange={(e) => setCustomKeywords(e.target.value)}
          placeholder="Enter your keywords here (one per line)"
          autoSize={{ minRows: 10, maxRows: 20 }}
        />
        <div style={{ marginTop: 8 }}>
          <Typography.Text type="secondary">
            Keywords count: {customKeywords.split('\n').filter(k => k.trim().length > 0).length}/1000
          </Typography.Text>
        </div>
      </Modal>
  </>
  )
}

export default ClusterTool